<template>
  <base-modal
    title="Publicación iniciada exitosamente"
    :loading="loading"
    @close="$emit('submit', null)"
    @submit="handleSubmit"
    textSave="Aceptar"
    iconSave=""
    hideCancel
  >
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <p>Este proceso puede tardar algunos minutos, el tiempo de duración dependerá de la cantidad de Estados de Cuentas sustituidos.</p>
        </v-col>
      </v-row>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/commons/BaseModal'

export default {
  components: { BaseModal },
  props: {},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', null)
    }
  }
}
</script>

<style></style>
