<template>
  <base-form-modal
    title="Sustitución de Estados de Cuentas"
    :loading="loading"
    @close="$emit('submit', null)"
    @submit="handleSubmit"
    textSave="Sustituir"
  >
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <p>
            <span class="black--text font-weight-bold">Generación efectuada: </span>
            <span>{{ accountStatement.generation_start_date| date('DD/MM/YYYY hh:mm a') }}</span>
          </p>
        </v-col>
        <v-col cols="12">
          <p>
            <span class="black--text font-weight-bold">Año: </span>
            <span>{{ accountStatement.year }}</span>
            <span class="black--text font-weight-bold ml-2">Mes: </span>
            <span>{{ handleMonth(accountStatement.month) }}</span>
          </p>
        </v-col>
        <v-col cols="12">
          <ValidationProvider
            name="Archivo - Estados de cuentas"
            :rules="{
              required: true,
              ext: 'txt',
              size: 300
            }"
            v-slot="{ errors }"
          >
            <v-file-input
              v-model="test"
              :rules="rules"
              :accept="fileType"
              dense
              show-size
              outlined
              class="pt-3"
              label="Archivo - Account Codes"
              @change="handleFile($event)"
              @click:clear="clearInputFile()"
              :error-messages="errors[0]"
            ></v-file-input>
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
  </base-form-modal>
</template>

<script>
import BaseFormModal from '@/components/commons/BaseFormModal'
import AccountStatementService from '@/services/accountStatement'
import { mapState } from 'vuex'

export default {
  components: { BaseFormModal },
  props: {
    accountStatement: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      data: null,
      test: null,
      rules: [
        value =>
          !value ||
          value.size < 300000 ||
          `El archivo debe pesar menos de 300KB`
      ],
      fileType: '.txt',
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        await AccountStatementService.post(this.data)
        this.$emit('submit', true)
      } catch(error) {
        console.log('error', error);
        this.$dialog.message.error('No se pudo guardar el registro.')
      } finally {
        this.loading = false
      }
    },
    handleMonth(value) {
      if (value < 10) {
        const numZeroes = 2 - value.toString().length + 1;
        if (numZeroes > 0) {
          return Array(+numZeroes).join("0") + value;
        }
      } else {
        return value
      }
    },
    handleFile(file) {
      if (file) {
        let fd = new FormData()
        fd.append("accountFile", file)
        fd.append('generation_user_id', this.user.id)
        fd.append('serie', 2)
        fd.append('month', parseInt(this.accountStatement.month))
        fd.append('year', parseInt(this.accountStatement.year))
        fd.append('public', 0)
        fd.append('type', 'part')
        fd.append('account_statement_id', this.accountStatement.id)
        fd.append('status_json', 'NEW')
        this.data = fd
      }
    },
    clearInputFile() {
      this.data = null
    }
  },
}
</script>

<style></style>
