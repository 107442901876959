<template>
  <div>
    <BaseForm
      :title="$route.meta.title"
      :loading="loading"
      @submit="handleSubmit"
      textSave="Generar"
    >
      <template v-slot:content>
        <v-row dense>
          <v-col cols="12">
            <v-radio-group row v-model="accountStatement.type">
              <v-radio label="Todos" value="all"></v-radio>
              <v-radio label="Parcial" value="part"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Año"
              :rules="{
                required: true,
                length: 4,
                max_value: currentYear,
                min_value: allowedYear
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Año *"
                placeholder="Año"
                v-model="accountStatement.year"
                v-mask="'####'"
                :error-messages="errors[0]"
                @input="handleYear"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Mes" rules="required" v-slot="{ errors }">
              <v-select
                v-model="accountStatement.month"
                :items="allowedMonths"
                label="Mes *"
                placeholder="Mes"
                item-value="code"
                item-text="month"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row v-if="accountStatement.type === 'part'" dense>
          <v-col cols="12">
            <ValidationProvider
              name="Archivo - Estados de cuentas"
              :rules="{
                required: true,
                ext: 'txt',
                size: 300
              }"
              v-slot="{ errors }"
            >
              <v-file-input
                v-model="test"
                :rules="rules"
                :accept="fileType"
                dense
                show-size
                outlined
                class="pt-2"
                label="Archivo - Account Codes"
                @change="handleFile($event)"
                @click:clear="clearInputFile()"
                :error-messages="errors[0]"
              ></v-file-input>
            </ValidationProvider>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import AccountStatementService from '@/services/accountStatement'
import SettingService from '@/services/setting'
import { mapState } from 'vuex'

export default {
  components: { BaseForm },
  data() {
    return {
      loading: false,
      accountStatement: {
        year: '',
        month: '',
        public: false,
        status_json: 'NEW',
        type: 'all'
      },
      months: [
        { code: '01', month: 'Enero' },
        { code: '02', month: 'Febrero' },
        { code: '03', month: 'Marzo' },
        { code: '04', month: 'Abril' },
        { code: '05', month: 'Mayo' },
        { code: '06', month: 'Junio' },
        { code: '07', month: 'Julio' },
        { code: '08', month: 'Agosto' },
        { code: '09', month: 'Septiembre' },
        { code: '10', month: 'Octubre' },
        { code: '11', month: 'Noviembre' },
        { code: '12', month: 'Diciembre' }
      ],
      monthSetting: null,
      test: null,
      rules: [
        value =>
          !value ||
          value.size < 300000 ||
          `El archivo debe pesar menos de 300KB`
      ],
      fileType: '.txt',
      data: null
    }
  },
  async created() {
    await this.getMonthSetting()
    if (this.currentMonth == 1) {
      this.accountStatement.year = this.lastYear
      this.accountStatement.month = '12'
    } else {
      this.accountStatement.year = this.currentYear
      this.accountStatement.month = this.lastMonth
    }
  },
  computed: {
    ...mapState('auth', ['user']),

    currentYear() {
      return this.$date().format('YYYY')
    },
    lastYear() {
      return this.$date()
        .subtract(1, 'year')
        .format('YYYY')
    },
    currentMonth() {
      return this.$date().format('MM')
    },
    lastMonth() {
      return this.$date()
        .subtract(1, 'month')
        .format('MM')
    },
    allowedMonths() {
      let filteredMonths = this.months

      if (this.accountStatement.year < this.allowedYear
        || this.accountStatement.year > this.currentYear
      )
        filteredMonths = []

      if (this.accountStatement.year == this.currentYear)
        filteredMonths = filteredMonths.filter(
          item => item.code < this.currentMonth
        )

      if (this.accountStatement.year == this.allowedYear)
        filteredMonths = filteredMonths.filter(
          item => item.code >= this.allowedMonth
        )

      return filteredMonths
    },

    allowedYear() {
      return this.$date()
        .subtract(this.monthSetting, 'month')
        .format('YYYY')
    },

    allowedMonth() {
      return this.$date()
        .subtract(this.monthSetting, 'month')
        .format('MM')
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        this.accountStatement.generation_user_id = this.user.id
        this.accountStatement.serie = 1
        this.accountStatement.month = parseInt(this.accountStatement.month)
        this.accountStatement.year = parseInt(this.accountStatement.year)

        let payload = null
        if (this.accountStatement.type === 'all') {
          payload = this.accountStatement
        } else {
          this.data.append('generation_user_id', this.user.id)
          this.data.append('month', parseInt(this.accountStatement.month))
          this.data.append('year', parseInt(this.accountStatement.year))
          this.data.append('type', 'part')
          this.data.append('status_json', 'NEW')
          this.data.append('public', 0)
          this.data.append('serie', 2)
          payload = this.data
        }
        await AccountStatementService.post(payload)
        this.$dialog.notify.success('Generación iniciada con éxito.')
        this.$router.go(-1)
      } catch {
        this.$dialog.message.error('No se pudo guardar el registro.')
      } finally {
        this.loading = false
      }
    },
    handleYear() {
      this.accountStatement.month = ''
    },
    async getMonthSetting() {
      const { data } = await SettingService.fetch({
        'filter[key]': 'months_old_allowed'
      })
      this.monthSetting = data.data[0]?.value
    },
    handleFile(file) {
      if (file) {
        let fd = new FormData()
        fd.append('accountFile', file)
        // fd.append('account_statement_id', this.accountStatement.id)
        this.data = fd
      }
    },
    clearInputFile() {
      this.data = null
    }
  }
}
</script>
