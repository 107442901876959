var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseList',{attrs:{"color":"primary","title":_vm.$route.meta.title,"section":_vm.$route.meta.section,"headers":_vm.headers,"items":_vm.accountStatements,"loading":_vm.loading,"totalRows":_vm.totalRows,"buttons":{ add: true, edit: false, delete: false, refresh: true, search: true, print: false },"addText":"Generar"},on:{"add":_vm.handleNew,"edit":_vm.handleEdit,"refresh":_vm.getData,"delete":_vm.handleDelete,"search":_vm.handleSearch,"options":_vm.handleOptions},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleMonth(value))+" ")])]}},{key:"item.status_json",fn:function(ref){
var value = ref.value;
return [_c('span',{class:_vm.handleStatusColor(value)},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.status_pdf",fn:function(ref){
var value = ref.value;
return [_c('span',{class:_vm.handleStatusColor(value)},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.status_publish",fn:function(ref){
var value = ref.value;
return [_c('span',{class:_vm.handleStatusColor(value)},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.job_id",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.handleJobId(value))+" ")]):_vm._e()]}},{key:"item.generation_user_id",fn:function(ref){
var item = ref.item;
return [(item.generation_user_id)?_c('span',[_vm._v(" "+_vm._s(item.generation_user.first_name)+" "+_vm._s(item.generation_user.last_name)+" ")]):_vm._e()]}},{key:"item.publish_user_id",fn:function(ref){
var item = ref.item;
return [(item.publish_user_id)?_c('span',[_vm._v(" "+_vm._s(item.publish_user.first_name)+" "+_vm._s(item.publish_user.last_name)+" ")]):_vm._e()]}},{key:"item.public",fn:function(ref){
var value = ref.value;
return [_c('v-simple-checkbox',{attrs:{"value":value,"disabled":""}})]}},{key:"item.generation_start_date",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value,'DD/MM/YYYY hh:mm a'))+" ")])]}},{key:"item.publish_start_date",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value,'DD/MM/YYYY hh:mm a'))+" ")])]}},{key:"custom-button",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD","disabled":_vm.publishButtonValidation(item)},on:{"click":function($event){return _vm.handlePublish(item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-clipboard-check-outline')}})],1)]}}],null,true)},[_c('span',[_vm._v("Publicar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD","disabled":_vm.substitutionButtonValidation(item)},on:{"click":function($event){return _vm.handleSubstitution(item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-file-swap-outline')}})],1)]}}],null,true)},[_c('span',[_vm._v("Sustituir")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD","disabled":_vm.accountCodesButtonValidation(item)},on:{"click":function($event){return _vm.handleAccounts(item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-dots-horizontal')}})],1)]}}],null,true)},[_c('span',[_vm._v("Account Codes")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"#8990AD","disabled":!item.log_file || _vm.loadingLog},on:{"click":function($event){return _vm.handleLog(item)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-math-log')}})],1)]}}],null,true)},[_c('span',[_vm._v("Log del proceso")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }