<template>
  <BaseList
    color="primary"
    :title="$route.meta.title"
    :section="$route.meta.section"
    :headers="headers"
    :items="accountStatements"
    :loading="loading"
    :totalRows="totalRows"
    :buttons="{ add: true, edit: false, delete: false, refresh: true, search: true, print: false }"
    addText="Generar"
    @add="handleNew"
    @edit="handleEdit"
    @refresh="getData"
    @delete="handleDelete"
    @search="handleSearch"
    @options="handleOptions"
  >
    <template #item.month="{ value }">
      <span>
        {{ handleMonth(value) }}
      </span>
    </template>
    <template #item.status_json="{ value }">
      <span :class="handleStatusColor(value)">
        {{ value }}
      </span>
    </template>
    <template #item.status_pdf="{ value }">
      <span :class="handleStatusColor(value)">
        {{ value }}
      </span>
    </template>
    <template #item.status_publish="{ value }">
      <span :class="handleStatusColor(value)">
        {{ value }}
      </span>
    </template>
    <template #item.job_id="{ value }">
      <span v-if="value">
        {{ handleJobId(value) }}
      </span>
    </template>
    <template #item.generation_user_id="{ item }">
      <span v-if="item.generation_user_id">
        {{ item.generation_user.first_name }} {{ item.generation_user.last_name }}
      </span>
    </template>
    <template #item.publish_user_id="{ item }">
      <span v-if="item.publish_user_id">
        {{ item.publish_user.first_name }} {{ item.publish_user.last_name }}
      </span>
    </template>
    <template #item.public="{ value }">
      <v-simple-checkbox
        :value="value"
        disabled
      />
    </template>
    <template #item.generation_start_date="{ value }">
      <span>
        {{ value | date('DD/MM/YYYY hh:mm a') }}
      </span>
    </template>
    <template #item.publish_start_date="{ value }">
      <span>
        {{ value | date('DD/MM/YYYY hh:mm a') }}
      </span>
    </template>
    <template #custom-button="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="mr-2"
            icon
            color="#8990AD"
            v-on="on"
            :disabled="publishButtonValidation(item)"
            @click="handlePublish(item)"
          >
            <v-icon v-text="'mdi-clipboard-check-outline'" />
          </v-btn>
        </template>
        <span>Publicar</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="mr-2"
            icon
            color="#8990AD"
            v-on="on"
            :disabled="substitutionButtonValidation(item)"
            @click="handleSubstitution(item)"
          >
            <v-icon v-text="'mdi-file-swap-outline'" />
          </v-btn>
        </template>
        <span>Sustituir</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="mr-2"
            icon
            color="#8990AD"
            v-on="on"
            @click="handleAccounts(item)"
            :disabled="accountCodesButtonValidation(item)"
          >
            <v-icon v-text="'mdi-dots-horizontal'" />
          </v-btn>
        </template>
        <span>Account Codes</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="mr-2"
            icon
            color="#8990AD"
            v-on="on"
            @click="handleLog(item)"
            :disabled="!item.log_file || loadingLog"
          >
            <v-icon v-text="'mdi-math-log'" />
          </v-btn>
        </template>
        <span>Log del proceso</span>
      </v-tooltip>
    </template>
  </BaseList>
</template>
<script>
import BaseList from '@/components/commons/BaseList'
import SubstitutionModal from './partials/SubstitutionModal'
import AccountCodesModal from './partials/AccountCodesModal'
import PublishValidationModal from './partials/PublishValidationModal'
import PublishInformationModal from './partials/PublishInformationModal'
import AccountStatementService from '@/services/accountStatement'
import SettingService from '@/services/setting'
import { hasPermission } from '@/utils'
import { mapState } from 'vuex'

export default {
  components: { BaseList },
  data() {
    return {
      substitutionSetting: null,
      options: {},
      loading: false,
      loadingLog: false,
      accountStatements: [],
      totalRows: 0,
      search: '',
      headers: [
        { text: 'Año', value: 'year' },
        { text: 'Mes', value: 'month' },
        { text: 'Tipo', value: 'type' },
        { text: 'Status JSON', value: 'status_json' },
        { text: 'JSON generados', value: 'json_count' },
        { text: 'Status PDF', value: 'status_pdf' },
        { text: 'PDF generados', value: 'pdf_count' },
        { text: 'Job id', value: 'job_id' },
        { text: 'Usuario generador', value: 'generation_user_id' },
        { text: 'Fecha de inicio de generación', value: 'generation_start_date' },
        { text: 'Usuario publicador', value: 'publish_user_id' },
        { text: 'Fecha de inicio de publicación', value: 'publish_start_date' },
        { text: 'Status Publicación', value: 'status_publish' },
        { text: 'Publicado', value: 'public', align: 'center' },
      ]
    }
  },
  async created() {
    try {
      await this.getSubstitutionSetting()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    setInterval( async () => {
      await this.getData()
    }, 60000);
  },
  computed: {
    ...mapState('auth', ['user']),
    currentDate() {
      return this.$date().format()
    },
    substitutionDateAllowed() {
      return this.$date().add(this.substitutionSetting, 'day').format()
    }
  },
  methods: {
    async getData() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options
      const sortValidation = [
        sortBy[0] ? sortBy[0] : 'generation_start_date',
        sortBy[0] ? sortDesc[0] : true
      ]
      this.loading = true
      try {
        const { data } = await AccountStatementService.fetch(
          {
            'filter[query]': this.search,
            include: 'generationUser,publishUser,accountCodes,substitutions,substituted'
          },
          sortValidation[0],
          sortValidation[1],
          page,
          itemsPerPage
        )
        this.accountStatements = data.data
        this.totalRows = data.total
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    async getSubstitutionSetting() {
      const { data } = await SettingService.fetch({
        'fields[settings]': 'id,key,value,type',
        'filter[key]': 'substitution_day_allowed',
      })
      this.substitutionSetting = data.data[0]?.value
    },

    handleNew() {
      this.$router.push({ name: 'AccountStatementGenerate' })
    },

    handleEdit(item) {
      this.$router.push({
        name: 'BrokerEdit',
        params: { id: item.id }
      })
    },

    async handlePublish(item) {
      if (this.publishValidaton(item)) {
        const action = await this.handlePublishValidationModal()
        if (!action) return
      }
      this.loading = true
      try {
        const payload = {
          id: item.id,
          publish_user_id: this.user.id
        }
        await AccountStatementService.publish(payload)
        await this.handlePublishInformationModal()
        this.getData()
      } catch {
        this.$dialog.message.error('No se pudo publicar el estado de cuenta.')
      } finally {
        this.loading = false
      }
    },

    publishValidaton(item) {
      return this.accountStatements.some(i => i.year == item.year && i.month == item.month && i.public)
      // return this.accountStatements.some(i => i.year == item.year && i.month == item.month && !i.public)
    },

    publishButtonValidation(item) {
      let disabled = item.status_pdf !== 'FINISHED' || (item.status_pdf == 'FINISHED' && item.public) || item.status_publish == 'PENDING'
      return !hasPermission(this.user, 'publish-account-statement') || disabled
    },

    substitutionButtonValidation(item) {
      const disabled = this.checkDateValidation(item)
      return item.type !== 'all' || disabled || !hasPermission(this.user, 'replace-account-statement')
    },

    accountCodesButtonValidation(item) {
      return !hasPermission(this.user, 'replace-account-statement') || item.type !== 'part' || !item.account_codes.length
    },

    checkDateValidation(item) {
      if (item.generation_end_date) {
        if (this.substitutionSetting === 0) return false
        const allowedDate = this.$date(item.generation_end_date).add(this.substitutionSetting, 'day').format('YYYY-MM-DD')
        return this.currentDate > allowedDate
      } else {
        return true
      }
    },

    async handleSubstitution(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '50%',
        transition: 'dialog-top-transition',
        accountStatement: item
      }

      const success = await this.$dialog.showAndWait(SubstitutionModal, params)
      if (success) {
        this.$dialog.notify.success('Sustitición iniciada con éxito.')
        this.getData()
      }
    },

    async handlePublishValidationModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '50%',
        transition: 'dialog-top-transition',
      }

      return await this.$dialog.showAndWait(PublishValidationModal, params)
    },
    async handlePublishInformationModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '50%',
        transition: 'dialog-top-transition',
        persistent: true
      }

      return await this.$dialog.showAndWait(PublishInformationModal, params)
    },

    async handleAccounts(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '50%',
        scrollable: true,
        transition: 'dialog-top-transition',
        accountStatement: item
      }

      await this.$dialog.showAndWait(AccountCodesModal, params)
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text:
          '¿ Desea eliminar el estado de cuenta ?'
      })

      if (!res) return false

      try {
        await AccountStatementService.delete(item.id)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error('No se ha podido eliminar el estado de cuenta.')
      }
    },

    handleOptions(options) {
      this.options = options
      this.getData()
    },

    handleSearch(value) {
      this.search = value
      this.getData()
    },

    handleStatusColor(value) {
      let color = ''
      switch (value) {
        case 'NEW':
          color = 'primary--text'
          break;
        case 'PENDING':
          color = 'warning--text'
          break;
        case 'FINISHED':
          color = 'success--text'
          break;
        case 'ERROR':
          color = 'error--text'
          break;
      }
      return color
    },
    
    handleJobId(value) {
      return `...${value.substring(value.length - 12)}`
    },

    handleMonth(value) {
      if (value < 10) {
        const numZeroes = 2 - value.toString().length + 1;
        if (numZeroes > 0) {
          return Array(+numZeroes).join("0") + value;
        }
      } else {
        return value
      }
    },
    async handleLog($accountStatement) {
      this.loadingLog = true
      try {
        const { data } = await AccountStatementService.downloadLog($accountStatement.id)
        this.download(data.file, $accountStatement)
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingLog = false
      }
    },
    download(content, $accountStatement) {
      const linkSource = `data:text/plain;base64,${content}`
      let downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = `log_${$accountStatement.job_id}.txt`
      downloadLink.click()
    }
  }
}
</script>

<style></style>
