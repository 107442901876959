import BaseService from './base'
import axios from '@/plugins/axios'

export default class AccountStatementService extends BaseService {
  static resource() {
    return 'api/v1/account_statements'
  }

  static async publish(payload) {
    const { data } = await axios.post(
      `${this.baseURL()}/${this.resource()}/publish`,
      payload
    )
    return data
  }

  static async substitution(payload) {
    const { data } = await axios.post(
      `${this.baseURL()}/${this.resource()}/substitution`,
      payload
    )
    return data
  }

  static async downloadTxt(id) {
    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}/substitution/download/${id}`
    )
    return data
  }
  static async downloadLog(id) {
    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}/downloadLog/${id}`
    )
    return data
  }
}
