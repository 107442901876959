<template>
  <base-modal
    title="Confimar inicio de publicación"
    :loading="loading"
    @close="$emit('submit', null)"
    @submit="handleSubmit"
    textSave="Publicar"
    iconSave=""
  >
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <p>Ya existe una generación de Estados de Cuentas publicada para este mismo año y mes, si procede a realizar esta publicación se sobreescribiran los Estados de Cuentas que ya han sido publicados para este año y mes.</p>
          <p class="font-weight-bold">¿Desea continuar e iniciar la publicación?</p>
        </v-col>
      </v-row>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/commons/BaseModal'

export default {
  components: { BaseModal },
  props: {},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', true)
    }
  }
}
</script>

<style></style>
