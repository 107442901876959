<template>
  <base-modal
    title="Account Codes sustituídos"
    :loading="loading"
    @close="$emit('submit', null)"
    @submit="handleSubmit"
    textSave="Aceptar"
    hideCancel
    iconSave=""
  >
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <p>
            <span class="black--text font-weight-bold">Generación efectuada: </span>
            <span>{{ accountStatement.generation_start_date| date('DD/MM/YYYY hh:mm a') }}</span>
          </p>
        </v-col>
        <v-col cols="12">
          <p>
            <span class="black--text font-weight-bold">Año: </span>
            <span>{{ accountStatement.year }}</span>
            <span class="black--text font-weight-bold ml-2">Mes: </span>
            <span>{{ handleMonth(accountStatement.month) }}</span>
          </p>
          <p>
            <span class="black--text font-weight-bold">Account Codes (.txt): </span>
            <span>{{ accountStatement.account_codes_count }}</span>
          </p>
          <p>
            <span class="black--text font-weight-bold">Account Codes (Sustituidos): </span>
            <span>{{ accountStatement.account_codes.length }}</span>
          </p>
          <p>
            <v-btn :loading="loading" color="primary" @click="handleTxt()">
              Descargar archivo
            </v-btn>
          </p>
        </v-col>
      </v-row>
      <v-row justify="end" dense>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            clearable
            light
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :items="accountStatement.account_codes"
            :loading="loading"
            :search="search"
            :headers="headers"
            class="mt-2"
            dense
          >
            <template #header.code="{ header }">
              <span class="black--text font-weight-bold">{{ header.text }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/commons/BaseModal'
import AccountStatementService from '@/services/accountStatement'
import { mapState } from 'vuex'

export default {
  components: { BaseModal },
  props: {
    accountStatement: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      search: '',
      headers: [{ text: 'Account Code', value: 'code' }]
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', null)
    },
    handleMonth(value) {
      if (value < 10) {
        const numZeroes = 2 - value.toString().length + 1
        if (numZeroes > 0) {
          return Array(+numZeroes).join("0") + value
        }
      } else {
        return value
      }
    },
    async handleTxt() {
      this.loading = true
      try {
        const { data } = await AccountStatementService.downloadTxt(this.accountStatement.id)
        this.download(data.file)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    download(content) {
      const linkSource = `data:text/plain;base64,${content}`
      let downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = `accountCodes_${this.accountStatement.job_id}.txt`
      downloadLink.click()
    }
  }
}
</script>

<style></style>
