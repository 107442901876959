var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseForm',{attrs:{"title":_vm.$route.meta.title,"loading":_vm.loading,"textSave":"Generar"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.accountStatement.type),callback:function ($$v) {_vm.$set(_vm.accountStatement, "type", $$v)},expression:"accountStatement.type"}},[_c('v-radio',{attrs:{"label":"Todos","value":"all"}}),_c('v-radio',{attrs:{"label":"Parcial","value":"part"}})],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Año","rules":{
              required: true,
              length: 4,
              max_value: _vm.currentYear,
              min_value: _vm.allowedYear
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"label":"Año *","placeholder":"Año","error-messages":errors[0]},on:{"input":_vm.handleYear},model:{value:(_vm.accountStatement.year),callback:function ($$v) {_vm.$set(_vm.accountStatement, "year", $$v)},expression:"accountStatement.year"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Mes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.allowedMonths,"label":"Mes *","placeholder":"Mes","item-value":"code","item-text":"month","error-messages":errors[0]},model:{value:(_vm.accountStatement.month),callback:function ($$v) {_vm.$set(_vm.accountStatement, "month", $$v)},expression:"accountStatement.month"}})]}}])})],1)],1),(_vm.accountStatement.type === 'part')?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Archivo - Estados de cuentas","rules":{
              required: true,
              ext: 'txt',
              size: 300
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pt-2",attrs:{"rules":_vm.rules,"accept":_vm.fileType,"dense":"","show-size":"","outlined":"","label":"Archivo - Account Codes","error-messages":errors[0]},on:{"change":function($event){return _vm.handleFile($event)},"click:clear":function($event){return _vm.clearInputFile()}},model:{value:(_vm.test),callback:function ($$v) {_vm.test=$$v},expression:"test"}})]}}],null,false,3450054134)})],1)],1):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }